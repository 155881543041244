<template>
    <div>
      <governorate-comp :type="type"></governorate-comp>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import GovernorateComp from "@/components/admin/governorate/GovernorateComp";
import {checkPermission} from "@/core/helpers/general";

export default defineComponent({
    name: "view-governorate",
    components : {GovernorateComp},

    setup() {

        const type = ref('view');
        onMounted(() => {
            setCurrentPageBreadcrumbs("View Governorate", ["Governorates"] , checkPermission('governorates.store') ? 'create-governorate': null);
        });


        return {
            getIllustrationsPath,
            type,
        };
    },

});
</script>
